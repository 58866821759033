import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useRef } from "react";
import EmailEditor from "react-email-editor";
import ButtonControl from "../../components/controls/ButtonControl";
import InputControl from "../../components/controls/InputControl";
import SelectControl from "../../components/controls/SelectControl";
import { API_MEDIAS } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useForm from "../../hooks/useForm";

const objRecord = {
  name: "",
  emailFor: 1,
  subject: "",
  template: "",
  templateDesign: "",
  pdf: "",
  pdfDesign: "",
  shopId: 0,
};

function EmailTemplateForm({
  addOrEdit,
  selectedRecord,
  openInDrawer,
  isSaving,
  shopId,
}) {
  const emailEditorRef = useRef(null);
  const pdfEditorRef = useRef(null);
  const { user } = useAppContext();

  const validate = (input = values) => {
    let temp = { ...errors };

    if ("name" in input)
      temp.name = input.name ? "" : "This field is required.";
    if ("subject" in input)
      temp.subject = input.subject ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, handleInputChange, errors, setErrors, resetForm } = useForm(
    selectedRecord ? selectedRecord : objRecord,
    true,
    validate
  );

  const templateVars = {
    subject: ["{{FULL-NAME}}"],
    email: ["{{DATE}}", "{{EMAIL}}", "{{FULL-NAME}}"],
    pdf: ["{{DATE}}", "{{EMAIL}}", "{{FULL-NAME}}"],
  };
  const vars = (id) => {
    switch (id) {
      case 4:
      case 5:
      case 6:
      case 8:
        templateVars.subject = [
          "{{INDUSTRY}}",
          "{{QUOTEID}}",
          "{{CUSTOMER-NAME}}",
        ];
        templateVars.email = [
          "{{DATE}}",
          "{{INDUSTRY}}",
          "{{QUOTEID}}",
          "{{CUSTOMER-NAME}}",
          "{{SHOP}}",
          "{{USER-NAME}}",
        ];
        templateVars.pdf = [
          "{{DATE}}",
          "{{INDUSTRY}}",
          "{{QUOTEID}}",
          "{{QUOTE-DATE}}",
          "{{QUOTE-EINFO}}",
          "{{QUOTE-IMGS}}",
          "{{QUOTE-NOTES}}",
          "{{QUOTE-ITEMS}}",
          "{{CUSTOMER-EMAIL}}",
          "{{CUSTOMER-NAME}}",
          "{{CUSTOMER-PHONE}}",
          "{{CUSTOMER-ADDRESS}}",
          "{{CUSTOMER-CITY}}",
          "{{CUSTOMER-STATE}}",
          "{{CUSTOMER-COUNTRY}}",
          "{{CUSTOMER-ZIP}}",
          "{{CUSTOMER-SIGN}}",
          "{{SHOP}}",
          "{{USER-NAME}}",
        ];
        return templateVars;

      case 2:
      case 3:
        templateVars.subject = [];
        templateVars.email = ["{{DATE}}", "{{EMAIL}}", "{{PIN}}"];
        templateVars.pdf = ["{{DATE}}", "{{EMAIL}}", "{{PIN}}"];
        return templateVars;

      default:
        return templateVars;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    pdfEditorRef.current?.editor.exportHtml((data) => {
      values.pdf = data.html;
      const stringifyDesign = JSON.stringify(data.design);
      values.pdfDesign = stringifyDesign;
    });

    emailEditorRef.current?.editor.exportHtml((data) => {
      values.template = data.html;
      const stringifyDesign = JSON.stringify(data.design);
      values.templateDesign = stringifyDesign;
      values.shopId = values.shopId ? values.shopId : shopId;
      if (validate()) {
        addOrEdit(values, resetForm);
      }
    });
  };

  const onLoadEmail = () => {
    if (values.templateDesign) {
      const parseDesign = JSON.parse(values.templateDesign);
      emailEditorRef.current?.editor.loadDesign(parseDesign);
    }
    emailEditorRef.current?.editor.addEventListener("image", imgUpload);
  };

  const onLoadPdf = () => {
    if (values.pdfDesign) {
      const parseDesign = JSON.parse(values.pdfDesign);
      pdfEditorRef.current?.editor.loadDesign(parseDesign);
    }
    pdfEditorRef.current?.editor.addEventListener("image", imgUpload);
  };

  const imgUpload = (file, done) => {
    var data = new FormData();
    data.append("file", file.attachments[0]);
    data.append("name", "email");

    fetch(API_MEDIAS, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.token,
        Accept: "application/json",
      },
      body: data,
    })
      .then((response) => {
        // Make sure the response was valid
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
          var error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // Pass the URL back to Unlayer to mark this upload as completed
        done({ progress: 100, url: data.location });
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Paper>
        <List>
          <ListItem
            secondaryAction={
              <Tooltip title="Close" placement="bottom" arrow>
                <Button
                  variant="text"
                  endIcon={<CloseIcon />}
                  onClick={() => openInDrawer()}
                >
                  Close
                </Button>
              </Tooltip>
            }
          >
            {selectedRecord ? (
              <>
                <ListItemAvatar>
                  <Avatar>
                    <EditIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Edit" secondary="Email Template" />
              </>
            ) : (
              <>
                <ListItemAvatar>
                  <Avatar>
                    <AddIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Add New" secondary="Email Template" />
              </>
            )}
          </ListItem>
        </List>
        <Divider />
        <Paper elevation={0} sx={{ p: 3 }}>
          <SelectControl
            label="Template For"
            name="emailFor"
            value={values.emailFor}
            onChange={handleInputChange}
            options={[
              { value: 1, label: "User New" },
              { value: 2, label: "User Return" },
              { value: 3, label: "User PIN" },
              { value: 4, label: "Quote Sent" },
              { value: 5, label: "Quote Approved" },
              { value: 6, label: "Quote Request" },
              { value: 7, label: "Install Booking" },
              { value: 8, label: "Design Notice" },
              { value: 9, label: "Freelance Contract" },
            ]}
          />
          <InputControl
            label="Name"
            name="name"
            value={values.name}
            onChange={handleInputChange}
            error={errors.name}
          />
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Typography variant="subtitle2">{`SUBJECT {vars}:`}</Typography>
            </Grid>
            {vars(values.emailFor).subject.map((el, i) => (
              <Grid item key={i}>
                <Chip label={el} />
              </Grid>
            ))}
          </Grid>
          <InputControl
            label="Subject"
            name="subject"
            value={values.subject}
            onChange={handleInputChange}
            error={errors.subject}
          />
          <FormControl fullWidth margin="normal">
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography variant="subtitle2">{`EMAIL {vars}:`}</Typography>
              </Grid>
              {vars(values.emailFor).email.map((el, i) => (
                <Grid item key={i}>
                  <Chip label={el} />
                </Grid>
              ))}
            </Grid>
            <Box border={1} borderColor="divider" borderRadius={1} mt={1}>
              <EmailEditor
                minHeight="77vh"
                ref={emailEditorRef}
                onLoad={onLoadEmail}
                options={{
                  version: "latest",
                  displayMode: "email",
                  features: {
                    ai: false,
                    textEditor: {
                      spellChecker: true,
                      tables: true,
                    },
                  },

                  textEditor: {
                    spellChecker: true,
                    tables: true,
                  },
                  appearance: {
                    theme: "modern_light",
                  },
                }}
              />
            </Box>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography variant="subtitle2">{`PDF {vars}:`}</Typography>
              </Grid>
              {vars(values.emailFor).pdf.map((el, i) => (
                <Grid item key={i}>
                  <Chip label={el} />
                </Grid>
              ))}
            </Grid>
            <Box border={1} borderColor="divider" borderRadius={1} mt={1}>
              <EmailEditor
                minHeight="77vh"
                ref={pdfEditorRef}
                onLoad={onLoadPdf}
                options={{
                  id: "editor",
                  projectId: 247060,
                  version: "latest",
                  displayMode: "web",
                  tabs: {
                    blocks: {
                      enabled: false,
                    },
                  },
                  features: {
                    ai: false,
                    textEditor: {
                      spellChecker: true,
                      tables: true,
                    },
                  },
                  appearance: {
                    theme: "modern_light",
                  },
                  tools: {
                    form: {
                      enabled: false,
                    },
                  },
                }}
              />
            </Box>
          </FormControl>
          <ButtonControl type="submit" text="Save" isSaving={isSaving} />
        </Paper>
      </Paper>
    </form>
  );
}

export default memo(EmailTemplateForm);
