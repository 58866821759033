import React, { createContext, useState } from "react";

export const AppContext = createContext({});

export function AppProvider({ children }) {
  const store = JSON.parse(localStorage.getItem("X-Access-Token"));
  const [user, setUser] = useState(store ? store : false);
  const [hubCon, setHubCon] = useState();
  const [alerts, setAlerts] = useState();
  const [alertCount, setAlertCount] = useState(0);

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        alerts,
        setAlerts,
        alertCount,
        setAlertCount,
        hubCon,
        setHubCon,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
