import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import TabPanel from "../../components/TabPanel";
import CustomersList from "./CustomersList";
import GroupsList from "./GroupsList";

export default function Customers() {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Tabs value={tabValue} onChange={handleChange} aria-label="tabs">
        <Tab label="Customers" />
        <Tab label="Companies" />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <CustomersList />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <GroupsList />
      </TabPanel>
    </>
  );
}
