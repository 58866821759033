import AddIcon from "@mui/icons-material/Add";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SearchIcon from "@mui/icons-material/Search";
import {
  CircularProgress,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InputControl from "../components/controls/InputControl";
import { API_QUOTES } from "../context/ApiEndPoints";
import useAppContext from "../hooks/useAppContext";
import useComponentContext from "../hooks/useComponentContext";
import useFetchList from "../hooks/useFetchList";
import useTable from "../hooks/useTable";
import { currencyFormat } from "../scripts/Scripts";
dayjs.extend(localizedFormat);

const columns = [
  { field: "quoteId", label: "ID", width: 2 },
  { field: "industry", label: "Industry / Title", width: 3 },
  { field: "totalPrice", label: "Total Price", width: 2 },
  { field: "user", label: "User", width: 3 },
  {
    field: "action",
    label: "Actions",
    width: 2,
    disableSorting: true,
    textCenter: true,
    isAction: true,
  },
];

function CustomerQuotesCard(props) {
  const { user } = useAppContext();
  const { scrhObjs, setScrhObjs } = useComponentContext();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { records, isLoading, fetchError } = useFetchList(
    `${API_QUOTES}/Customer/${props.quoteId}`
  );

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { TblHead, TblContainer, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, columns, filterFn);

  const handleSearch = (e) => {
    const { name, value } = e.target;
    const index = scrhObjs.findIndex((el) => el.name === name);
    if (index >= 0) {
      scrhObjs[index] = { name, value };
    } else {
      scrhObjs.push({ name, value });
    }

    setScrhObjs((scrhObjs) => scrhObjs.filter((el) => el.value !== ""));
  };

  useEffect(() => {
    let timeOut = setTimeout(() => {
      setFilterFn({
        fn: (items) => {
          let x;
          for (const { name, value } of scrhObjs) {
            x = items.filter((item) =>
              item[name]?.toString().toLowerCase().includes(value.toLowerCase())
            );
            items = x;
          }
          return items;
        },
      });
    }, 300);

    return () => clearTimeout(timeOut);
    // eslint-disable-next-line
  }, [scrhObjs]);

  return (
    <>
      {fetchError ||
        (isLoading && (
          <CircularProgress size={20} color="inherit" sx={{ m: 2 }} />
        ))}
      {records && (
        <>
          <Toolbar component={Paper} square disableGutters sx={{ mb: 0.1 }}>
            <Grid container alignItems="center">
              {columns.map((column) =>
                !column.isAction ? (
                  <Grid item xs={column.width} key={column.field} sx={{ p: 2 }}>
                    {!column.filters ? (
                      <InputControl
                        variant="standard"
                        label={`Search ${column.label}`}
                        name={column.field}
                        value={
                          scrhObjs.find((el) => el.name === column.field)
                            ?.value || ""
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => handleSearch(e)}
                        className="srchInput"
                        margin="none"
                        fullWidth={false}
                      />
                    ) : (
                      <TextField
                        select
                        variant="standard"
                        label={`Search ${column.label}`}
                        name={column.field}
                        value={
                          scrhObjs.find((el) => el.name === column.field)
                            ?.value || ""
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => handleSearch(e)}
                        className="srchInput"
                        margin="none"
                        fullWidth={true}
                      >
                        {column.filters.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={column.width}
                    key={column.field}
                    sx={{ p: 2 }}
                    textAlign="right"
                  >
                    {" "}
                    {user?.claims.includes("job_add") && (
                      <Tooltip title="Add New" placement="top" arrow>
                        <Fab
                          color="primary"
                          aria-label="add"
                          component={Link}
                          to="/add-quote"
                        >
                          <AddIcon />
                        </Fab>
                      </Tooltip>
                    )}
                  </Grid>
                )
              )}
            </Grid>
          </Toolbar>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: "69vh" }}>
              <TblContainer>
                {records.length === 0 && <caption>No Record Found!</caption>}
                <TblHead />
                <TableBody>
                  {records &&
                    recordsAfterPagingAndSorting().map((record, index) => (
                      <TableRow
                        key={record.quoteId}
                        selected={selectedIndex === index}
                        onClick={() => setSelectedIndex(index)}
                        hover
                      >
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.quoteId}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "pre-line",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.industry}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {currencyFormat(record.totalPrice, record.currency)}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {record.user}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                          }}
                        >
                          <Tooltip title="Go To Quote" placement="top" arrow>
                            <IconButton
                              aria-label="edit"
                              color="primary"
                              component={Link}
                              to={`/job-details/${record.quoteId}`}
                            >
                              <RequestQuoteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </TblContainer>
            </TableContainer>
            <TblPagination />
          </Paper>
        </>
      )}
    </>
  );
}

export default memo(CustomerQuotesCard);
