import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import EditIcon from "@mui/icons-material/Edit";

import {
  Alert,
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { memo, useState } from "react";
import ApiFetch from "../../components/ApiFetch";
import FilesUploader from "../../components/FilesUploader";
import PopupDialog from "../../components/PopupDialog";
import ButtonControl from "../../components/controls/ButtonControl";
import InputControl from "../../components/controls/InputControl";
import SelectControl from "../../components/controls/SelectControl";
import {
  API_QUOTES,
  API_SCHEDULES,
  API_USERS,
  BASEURL,
} from "../../context/ApiEndPoints";
import useFetchList from "../../hooks/useFetchList";
import useForm from "../../hooks/useForm";

function InstallForm({
  addOrEdit,
  handleFileDelete,
  handleSms,
  handleEmail,
  handleComplete,
  selectedRecord,
  setSelectedRecord,
  isSaving,
  setConfirmDialog,
  quoteId,
  user,
}) {
  const objRecord = {
    startTime: null,
    endTime: null,
    quoteItems: [],
    recivedEntity: "",
    additionalService: "",
    remarks: "",
    userId: "",
    quoteId: quoteId,
  };
  const [popupDialog, setPopupDialog] = useState(false);
  const [popupContent, setPopupContent] = useState(null);

  const validate = (input = values) => {
    let temp = { ...errors };

    if ("services" in input) {
      temp.quoteItems =
        input.quoteItems.length > 0 ? "" : "This field is required.";
    }

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    useForm(selectedRecord ? selectedRecord : objRecord, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  const handleConfirm = async (id) => {
    // GET Request using fetch
    const requestOptions = {
      method: "GET",
      headers: { Authorization: "Bearer " + user.token },
    };
    const results = await ApiFetch(
      `${API_SCHEDULES}/Confirm/${id}`,
      requestOptions
    );

    if (results) setSelectedRecord({ ...results });
  };

  const {
    records: itemsOptions,
    isLoading: itemsIsLoading,
    fetchError: itemsFetchError,
  } = useFetchList(
    `${API_QUOTES}/QuoteItems/${
      selectedRecord ? selectedRecord.quoteId : quoteId
    }`
  );

  const {
    records: usersSelect,
    isLoading: userIsLoading,
    fetchError: userFetchError,
  } = useFetchList(API_USERS);

  return (
    <>
      <Paper>
        <List>
          {selectedRecord ? (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <EditIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Edit" secondary="Schedule" />
            </ListItem>
          ) : (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add New" secondary="Schedule" />
            </ListItem>
          )}
        </List>
        <Divider />
        {selectedRecord?.isConfirmed === true ? (
          <Alert severity="success">
            Confirmed - QUOTE ID: {selectedRecord.quoteId}
          </Alert>
        ) : selectedRecord?.isConfirmed === false ? (
          <Alert severity="warning">
            Unconfirmed - QUOTE ID: {selectedRecord.quoteId}
          </Alert>
        ) : (
          ""
        )}
        <Paper elevation={0} sx={{ p: 3 }}>
          {selectedRecord && !selectedRecord.isCompleted && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              py={1}
            >
              <Box>
                <ButtonControl
                  text="SMS Customer"
                  color="inherit"
                  isSaving={isSaving}
                  onClick={() => handleSms(selectedRecord.scheduleId)}
                  sx={{ mr: 1 }}
                />
                <ButtonControl
                  text="Email Customer"
                  color="inherit"
                  isSaving={isSaving}
                  onClick={() => handleEmail(selectedRecord.scheduleId)}
                />
              </Box>
              <ButtonControl
                text="Confirm Schedule"
                color="success"
                isSaving={isSaving}
                onClick={() => handleConfirm(selectedRecord.scheduleId)}
              />
            </Stack>
          )}
          <Divider sx={{ my: 2 }} />
          <form onSubmit={handleSubmit}>
            <Stack direction="row" alignItems="center">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Start Date Time"
                  value={
                    values.startTime
                      ? dayjs(values.startTime)
                      : dayjs().hour(0).minute(0)
                  }
                  onChange={(newValue) =>
                    setValues((values) => ({ ...values, startTime: newValue }))
                  }
                  timeSteps={{ minutes: 15 }}
                  ampm={false}
                  disablePast
                  sx={{ width: "100%" }}
                />
                <DateTimePicker
                  label="End Date Time"
                  value={values.endTime ? dayjs(values.endTime) : null}
                  onChange={(newValue) =>
                    setValues((values) => ({ ...values, endTime: newValue }))
                  }
                  timeSteps={{ minutes: 15 }}
                  ampm={false}
                  disablePast
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </Stack>
            {itemsFetchError ||
              (itemsIsLoading && (
                <CircularProgress size={20} color="inherit" />
              ))}
            {itemsOptions && (
              <SelectControl
                label="Services"
                name="quoteItems"
                value={values.quoteItems}
                onChange={handleInputChange}
                options={itemsOptions}
                error={errors.quoteItems}
                valueIndex={1}
                multiple
              />
            )}
            <InputControl
              label="Recived Entity ID (If any)"
              name="recivedEntity"
              value={values.recivedEntity}
              onChange={handleInputChange}
              error={errors.recivedEntity}
            />
            <InputControl
              label="Additional Service Required (If any)"
              name="additionalService"
              value={values.additionalService}
              onChange={handleInputChange}
              error={errors.additionalService}
            />
            <InputControl
              label="Remarks"
              name="remarks"
              value={values.remarks}
              onChange={handleInputChange}
              error={errors.remarks}
              multiline
              rows={3}
            />
            {userFetchError ||
              (userIsLoading && <CircularProgress size={20} color="inherit" />)}
            {usersSelect && user?.roles.includes("Admin") && (
              <SelectControl
                label="User"
                name="userId"
                value={values.userId}
                onChange={handleInputChange}
                options={usersSelect}
                error={errors.userId}
              />
            )}
            <InputLabel>Picture(s) Before Service</InputLabel>
            <Grid container spacing={1}>
              {selectedRecord &&
                selectedRecord.scheduleMedias
                  .filter((e) => e.category === 1)
                  .map((el, i) => (
                    <Grid item key={i}>
                      <Card>
                        <CardHeader
                          action={
                            <Tooltip title="Delete" placement="top" arrow>
                              <IconButton
                                color="secondary"
                                onClick={(e) =>
                                  setConfirmDialog({
                                    isOpen: true,
                                    title: "Are you sure to delete this file?",
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => {
                                      handleFileDelete(e, el.scheduleMediaId);
                                    },
                                  })
                                }
                                aria-label="delete"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          }
                          title={el.mime}
                          titleTypographyProps={{
                            fontSize: "small",
                          }}
                        />
                        {el.mime.includes("image") ? (
                          <>
                            <CardActionArea
                              onClick={() => {
                                setPopupContent(
                                  `${BASEURL}/img/${el.fileName}`
                                );
                                setPopupDialog(true);
                              }}
                            >
                              <CardMedia
                                component="img"
                                height="144"
                                image={`${BASEURL}/img/${el.fileName}`}
                                alt={el.fileName}
                                loading="lazy"
                              />
                            </CardActionArea>
                          </>
                        ) : el.mime === "application/pdf" ? (
                          <CardContent sx={{ textAlign: "center" }}>
                            <embed
                              src={`${BASEURL}/img/${el.fileName}#view=FitV`}
                              type="application/pdf"
                              width="100%"
                              height="144"
                              loading="lazy"
                            />
                            <ButtonControl
                              component={Link}
                              href={`${BASEURL}/img/${el.fileName}`}
                              text="View"
                              variant="text"
                              size="medium"
                              startIcon={<DownloadRoundedIcon />}
                              download={el.fileName}
                              target="_blank"
                              sx={{ textTransform: "lowercase" }}
                            />
                            <Divider light />
                          </CardContent>
                        ) : (
                          <>
                            <CardMedia
                              component="img"
                              height="144"
                              image="img/file.png"
                              alt={el.fileName}
                              loading="lazy"
                            />
                            <CardContent sx={{ textAlign: "center" }}>
                              <ButtonControl
                                component={Link}
                                href={`${BASEURL}/img/${el.fileName}`}
                                text="Download"
                                variant="text"
                                size="medium"
                                startIcon={<DownloadRoundedIcon />}
                                download={el.fileName}
                                sx={{ textTransform: "lowercase" }}
                              />
                              <Divider light />
                            </CardContent>
                          </>
                        )}
                      </Card>
                    </Grid>
                  ))}
            </Grid>
            <FormControl margin="dense" fullWidth>
              <FilesUploader
                inputName="beforeFiles"
                accept="image/*"
                values={values}
                setValues={setValues}
              />
            </FormControl>
            <InputLabel>Picture(s) After Service</InputLabel>
            <Grid container spacing={1}>
              {selectedRecord &&
                selectedRecord.scheduleMedias
                  .filter((e) => e.category === 2)
                  .map((el, i) => (
                    <Grid item key={i}>
                      <Card>
                        <CardHeader
                          action={
                            <Tooltip title="Delete" placement="top" arrow>
                              <IconButton
                                color="secondary"
                                onClick={(e) =>
                                  setConfirmDialog({
                                    isOpen: true,
                                    title: "Are you sure to delete this file?",
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => {
                                      handleFileDelete(e, el.scheduleMediaId);
                                    },
                                  })
                                }
                                aria-label="delete"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          }
                          title={el.mime}
                          titleTypographyProps={{
                            fontSize: "small",
                          }}
                        />
                        {el.mime.includes("image") ? (
                          <>
                            <CardActionArea
                              onClick={() => {
                                setPopupContent(
                                  `${BASEURL}/img/${el.fileName}`
                                );
                                setPopupDialog(true);
                              }}
                            >
                              <CardMedia
                                component="img"
                                height="144"
                                image={`${BASEURL}/img/${el.fileName}`}
                                alt={el.fileName}
                                loading="lazy"
                              />
                            </CardActionArea>
                          </>
                        ) : el.mime === "application/pdf" ? (
                          <CardContent sx={{ textAlign: "center" }}>
                            <embed
                              src={`${BASEURL}/img/${el.fileName}#view=FitV`}
                              type="application/pdf"
                              width="100%"
                              height="144"
                              loading="lazy"
                            />
                            <ButtonControl
                              component={Link}
                              href={`${BASEURL}/img/${el.fileName}`}
                              text="View"
                              variant="text"
                              size="medium"
                              startIcon={<DownloadRoundedIcon />}
                              download={el.fileName}
                              target="_blank"
                              sx={{ textTransform: "lowercase" }}
                            />
                            <Divider light />
                          </CardContent>
                        ) : (
                          <>
                            <CardMedia
                              component="img"
                              height="144"
                              image="img/file.png"
                              alt={el.fileName}
                              loading="lazy"
                            />
                            <CardContent sx={{ textAlign: "center" }}>
                              <ButtonControl
                                component={Link}
                                href={`${BASEURL}/img/${el.fileName}`}
                                text="Download"
                                variant="text"
                                size="medium"
                                startIcon={<DownloadRoundedIcon />}
                                download={el.fileName}
                                sx={{ textTransform: "lowercase" }}
                              />
                              <Divider light />
                            </CardContent>
                          </>
                        )}
                      </Card>
                    </Grid>
                  ))}
            </Grid>
            <FormControl margin="dense" fullWidth>
              <FilesUploader
                inputName="afterFiles"
                accept="image/*"
                values={values}
                setValues={setValues}
              />
            </FormControl>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              py={1}
            >
              <ButtonControl
                type="submit"
                text="Save"
                isSaving={isSaving}
                disabled={selectedRecord && selectedRecord.isCompleted}
              />
              {selectedRecord && !selectedRecord.isCompleted && (
                <ButtonControl
                  text="Close Job"
                  color="error"
                  isSaving={isSaving}
                  onClick={(e) =>
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure you want to CLOSE this job?",
                      subTitle: "it can NOT be reopened!",
                      onConfirm: () => {
                        handleComplete(selectedRecord.scheduleId);
                      },
                    })
                  }
                />
              )}
            </Stack>
          </form>
        </Paper>
      </Paper>
      <PopupDialog popupDialog={popupDialog} setPopupDialog={setPopupDialog}>
        <img src={popupContent} alt={popupContent} />
      </PopupDialog>
    </>
  );
}

export default memo(InstallForm);
