export function getRandomColor(name) {
  // get first alphabet in upper case
  const firstAlphabet = name.charAt(0).toLowerCase();
  // get the ASCII code of the character
  const asciiCode = firstAlphabet.charCodeAt(0);
  // number that contains 3 times ASCII value of character -- unique for every alphabet
  const colorNum =
    asciiCode.toString() + asciiCode.toString() + asciiCode.toString();

  var num = Math.round(0xffffff * parseInt(colorNum));
  var r = num & 255;
  var g = (num >> 8) & 255;
  var b = num & 255;

  return "rgb(" + r + ", " + g + ", " + b + ", 0.1)";
}

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  const a = name.replace(/[^\w]+/g, " ").trim();
  return {
    sx: { bgcolor: stringToColor(a) },
    children:
      a.split(" ").length > 1
        ? `${a.split(" ")[0][0]}${a.split(" ")[1][0]}`
        : a[0],
  };
}

// export function currencyFormat(num, currency) {
//   return (
//     currency +
//     " " +
//     parseInt(num)
//       .toFixed(2)
//       .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
//   );
// }

export function currencyFormat(n, currency) {
  if (!isNaN(n - parseFloat(n)))
    return n ? `${currency}  ${n.toLocaleString("lookup")}` : "";
}

export function dateStamp(date) {
  if (date)
    return new Date(date).toLocaleDateString("lookup", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
}

export function timeStamp(date) {
  if (date)
    return new Date(date).toLocaleTimeString("lookup", {
      hour: "2-digit",
      minute: "2-digit",
    });
}

export function dateTimeStamp(date) {
  if (date)
    return new Date(date).toLocaleString("lookup", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
}

export function scheduleStamp(date) {
  if (date)
    return new Date(date).toLocaleString("lookup", {
      hour: "2-digit",
      minute: "2-digit",
      weekday: "short",
      day: "numeric",
      month: "short",
    });
}
