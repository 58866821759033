import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
} from "@mui/material";
import React, { memo } from "react";
import ButtonControl from "../../components/controls/ButtonControl";
import CheckboxControl from "../../components/controls/CheckboxControl";
import InputControl from "../../components/controls/InputControl";
import SelectControl from "../../components/controls/SelectControl";
import { API_ROLES, API_SHOPS_SELECT } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useFetchList from "../../hooks/useFetchList";
import useForm from "../../hooks/useForm";

const objRecord = {
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  country: "United States",
  roles: [],
  shopId: "",
};

function UserForm(props) {
  const { addOrEdit, selectedRecord, openInDrawer, isSaving } = props;
  const { user } = useAppContext();

  const validate = (input = values) => {
    let temp = { ...errors };
    if ("email" in input) {
      temp.email = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i.test(
        input.email
      )
        ? ""
        : "Email in not valid.";
      temp.email = input.email ? temp.email : "Email is required.";
    }
    if ("shopId" in input)
      temp.shopId = input.shopId ? "" : "This field is required.";
    if ("country" in input)
      temp.country = input.country ? "" : "This field is required.";
    if ("roles" in input)
      temp.roles = input.roles.length > 0 ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, handleInputChange, errors, setErrors, resetForm } = useForm(
    selectedRecord ? selectedRecord : objRecord,
    true,
    validate
  );

  const {
    records: roleOptions,
    isLoading: roleIsLoading,
    fetchError: roleFetchError,
  } = useFetchList(API_ROLES);

  const {
    records: shopsSelect,
    isLoading: shopsLoading,
    fetchError: shopsError,
  } = useFetchList(API_SHOPS_SELECT);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Paper>
        <List>
          {selectedRecord ? (
            <ListItem
              secondaryAction={
                <Tooltip title="Details" placement="bottom" arrow>
                  <IconButton
                    color="primary"
                    aria-label="details"
                    onClick={() => openInDrawer("details", selectedRecord)}
                  >
                    <ArticleIcon />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <EditIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Edit" secondary="User" />
            </ListItem>
          ) : (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add New" secondary="User" />
            </ListItem>
          )}
        </List>
        <Divider />
        <Box sx={{ p: 3 }}>
          {selectedRecord && (
            <CheckboxControl
              label="Block"
              name="block"
              value={values.block}
              onChange={handleInputChange}
            />
          )}
          <InputControl
            type="email"
            label="Email"
            name="email"
            value={values.email}
            onChange={handleInputChange}
            error={errors.email}
            disabled={selectedRecord?.email ? true : false}
          />
          <InputControl
            label="First Name"
            name="firstName"
            value={values.firstName}
            onChange={handleInputChange}
            error={errors.firstName}
          />
          <InputControl
            label="Last Name"
            name="lastName"
            value={values.lastName}
            onChange={handleInputChange}
            error={errors.lastName}
          />
          <InputControl
            type="tel"
            label="Phone No."
            name="phoneNumber"
            value={values.phoneNumber}
            onChange={handleInputChange}
            error={errors.phoneNumber}
          />
          <SelectControl
            label="Select Country"
            name="country"
            value={values.country}
            onChange={handleInputChange}
            options={countries}
            error={errors.country}
            disabled={selectedRecord?.country ? true : false}
          />
          {roleFetchError ||
            (roleIsLoading && <CircularProgress size={20} color="inherit" />)}
          {roleOptions && (
            <SelectControl
              label="Select Role"
              name="roles"
              value={values.roles}
              onChange={handleInputChange}
              options={roleOptions}
              error={errors.roles}
              valueIndex={1}
              multiple
            />
          )}
          {shopsError ||
            (shopsLoading && <CircularProgress size={20} color="inherit" />)}
          {shopsSelect && user?.roles.includes("Admin") && (
            <SelectControl
              label="Shop"
              name="shopId"
              value={values.shopId}
              onChange={handleInputChange}
              options={shopsSelect}
              error={errors.shopId}
              textIndex={1}
            />
          )}
          {roleOptions && shopsSelect && (
            <ButtonControl type="submit" text="Save" isSaving={isSaving} />
          )}
        </Box>
      </Paper>
    </form>
  );
}

const countries = [
  { code: "Belgium", label: "Belgium" },
  { code: "United States", label: "United States" },
];

export default memo(UserForm);
