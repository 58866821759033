import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
} from "@mui/material";
import React, { memo } from "react";
import ButtonControl from "../../components/controls/ButtonControl";
import InputControl from "../../components/controls/InputControl";
import useForm from "../../hooks/useForm";

const objRecord = {
  name: "",
  email: "",
  phoneNumber: "",
  address: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  taxNumber: "",
};

function GroupForm(props) {
  const { addOrEdit, selectedRecord, openInDrawer, isSaving } = props;

  const validate = (input = values) => {
    let temp = { ...errors };

    if ("name" in input)
      temp.name = input.name ? "" : "This field is required.";

    if ("email" in input) {
      temp.email = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i.test(
        input.email
      )
        ? ""
        : "Email in not valid.";
    }

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, handleInputChange, errors, setErrors, resetForm } = useForm(
    selectedRecord ? selectedRecord : objRecord,
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Paper>
        <List>
          {selectedRecord ? (
            <ListItem
              secondaryAction={
                <Tooltip title="Details" placement="bottom" arrow>
                  <IconButton
                    color="primary"
                    aria-label="details"
                    onClick={() => openInDrawer("details", selectedRecord)}
                  >
                    <ArticleIcon />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <EditIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Edit" secondary="Company" />
            </ListItem>
          ) : (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add New" secondary="Company" />
            </ListItem>
          )}
        </List>
        <Divider />
        <Box sx={{ p: 3 }}>
          <InputControl
            label="Name"
            name="name"
            value={values.name}
            onChange={handleInputChange}
            error={errors.name}
          />
          <InputControl
            type="email"
            label="Email"
            name="email"
            value={values.email}
            onChange={handleInputChange}
            error={errors.email}
          />
          <InputControl
            label="Phone No."
            name="phoneNumber"
            value={values.phoneNumber}
            onChange={handleInputChange}
            error={errors.phoneNumber}
          />
          <InputControl
            label="Address"
            name="address"
            value={values.address}
            onChange={handleInputChange}
            error={errors.address}
          />
          <InputControl
            label="City"
            name="city"
            value={values.city}
            onChange={handleInputChange}
            error={errors.city}
          />
          <InputControl
            label="State"
            name="state"
            value={values.state}
            onChange={handleInputChange}
            error={errors.state}
          />
          <InputControl
            label="Country"
            name="country"
            value={values.country}
            onChange={handleInputChange}
            error={errors.country}
          />
          <InputControl
            label="Zip Code"
            name="zipCode"
            value={values.zipCode}
            onChange={handleInputChange}
            error={errors.zipCode}
          />
          <InputControl
            label="Tax No."
            name="taxNumber"
            value={values.taxNumber}
            onChange={handleInputChange}
            error={errors.taxNumber}
          />
          <ButtonControl type="submit" text="Save" isSaving={isSaving} />
        </Box>
      </Paper>
    </form>
  );
}

export default memo(GroupForm);
