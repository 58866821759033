import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
} from "@mui/material";
import React, { memo } from "react";
import ButtonControl from "../../components/controls/ButtonControl";
import InputControl from "../../components/controls/InputControl";
import SelectControl from "../../components/controls/SelectControl";
import useForm from "../../hooks/useForm";

const objRecord = {
  shopId: 0,
  title: "",
  phoneNumber: "",
  address: "",
  city: "",
  state: "",
  country: "United States",
  zipCode: "",
  taxNumber: "",
  facebookPage: "",
  googleBusiness: "",
  instagram: "",
};

function ShopForm(props) {
  const { addOrEdit, selectedRecord, openInDrawer, isSaving } = props;

  const validate = (input = values) => {
    let temp = { ...errors };

    if ("email" in input) {
      temp.email = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i.test(
        input.email
      )
        ? ""
        : "Email in not valid.";
      temp.email = input.email ? temp.email : "Email is required.";
    }

    if ("title" in input)
      temp.title = input.title ? "" : "This field is required.";

    if ("city" in input)
      temp.city = input.city ? "" : "This field is required.";

    if ("state" in input)
      temp.state = input.state ? "" : "This field is required.";

    if ("country" in input)
      temp.country = input.country ? "" : "This field is required.";

    if ("zipCode" in input)
      temp.zipCode = input.zipCode ? "" : "This field is required.";

    if ("phoneNumber" in input)
      temp.phoneNumber = input.phoneNumber ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, handleInputChange, errors, setErrors, resetForm } = useForm(
    selectedRecord ? selectedRecord : objRecord,
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Paper>
        <List>
          {selectedRecord ? (
            <ListItem
              secondaryAction={
                <Tooltip title="Details" placement="bottom" arrow>
                  <IconButton
                    color="primary"
                    aria-label="details"
                    onClick={() => openInDrawer("details", selectedRecord)}
                  >
                    <ArticleIcon />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <EditIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Edit" secondary="Shop" />
            </ListItem>
          ) : (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add New" secondary="Shop" />
            </ListItem>
          )}
        </List>
        <Divider />
        <Box sx={{ p: 3 }}>
          <input
            type="hidden"
            name="shopId"
            value={values.shopId}
            readOnly
            hidden
          />
          <InputControl
            label="Title"
            name="title"
            value={values.title}
            onChange={handleInputChange}
            error={errors.title}
          />
          <InputControl
            label="Phone No."
            name="phoneNumber"
            value={values.phoneNumber}
            onChange={handleInputChange}
            error={errors.phoneNumber}
          />
          <InputControl
            label="Address"
            name="address"
            value={values.address}
            onChange={handleInputChange}
            error={errors.address}
          />
          <InputControl
            label="City"
            name="city"
            value={values.city}
            onChange={handleInputChange}
            error={errors.city}
          />
          <InputControl
            label="State"
            name="state"
            value={values.state}
            onChange={handleInputChange}
            error={errors.state}
          />
          <SelectControl
            label="Select Country"
            name="country"
            value={values.country}
            onChange={handleInputChange}
            options={countries}
            error={errors.country}
            disabled={selectedRecord?.country ? true : false}
          />
          <InputControl
            label="Zip Code"
            name="zipCode"
            value={values.zipCode}
            onChange={handleInputChange}
            error={errors.zipCode}
          />
          <InputControl
            label="Tax No."
            name="taxNumber"
            value={values.taxNumber}
            onChange={handleInputChange}
            error={errors.taxNumber}
          />
          <InputControl
            label="Facebook Page"
            name="facebookPage"
            value={values.facebookPage}
            onChange={handleInputChange}
            error={errors.facebookPage}
          />
          <InputControl
            label="Google Business"
            name="googleBusiness"
            value={values.googleBusiness}
            onChange={handleInputChange}
            error={errors.googleBusiness}
          />
          <InputControl
            label="Instagram"
            name="instagram"
            value={values.instagram}
            onChange={handleInputChange}
            error={errors.instagram}
          />
          <ButtonControl type="submit" text="Save" isSaving={isSaving} />
        </Box>
      </Paper>
    </form>
  );
}

const countries = [
  { code: "Belgium", label: "Belgium" },
  { code: "United States", label: "United States" },
];

export default memo(ShopForm);
