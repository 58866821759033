import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
} from "@mui/material";
import React, { memo } from "react";
import FilesUploader from "../../components/FilesUploader";
import ButtonControl from "../../components/controls/ButtonControl";
import CheckboxControl from "../../components/controls/CheckboxControl";
import InputControl from "../../components/controls/InputControl";
import SelectControl from "../../components/controls/SelectControl";
import {
  API_GROUPS,
  API_SHOPS_SELECT,
  API_USERS,
} from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useFetchList from "../../hooks/useFetchList";
import useForm from "../../hooks/useForm";

const objRecord = {
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  address: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  taxNumber: "",
  whereFindUs: "Social Media",
  notes: "",
  userId: "",
  shopId: "",
  groupId: "",
};

function CustomerForm(props) {
  const { addOrEdit, selectedRecord, openInDrawer, isSaving } = props;
  const { user } = useAppContext();

  const validate = (input = values) => {
    let temp = { ...errors };

    if ("email" in input) {
      temp.email = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i.test(
        input.email
      )
        ? ""
        : "Email in not valid.";
      temp.email = input.email ? temp.email : "Email is required.";
    }
    if ("firstName" in input)
      temp.firstName = input.firstName ? "" : "This field is required.";
    if ("lastName" in input)
      temp.lastName = input.lastName ? "" : "This field is required.";
    if ("userId" in input)
      temp.userId = input.userId ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    useForm(selectedRecord ? selectedRecord : objRecord, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  const {
    records: usersSelect,
    isLoading: userIsLoading,
    fetchError: userError,
  } = useFetchList(API_USERS);

  const {
    records: shopsSelect,
    isLoading: shopsLoading,
    fetchError: shopsError,
  } = useFetchList(API_SHOPS_SELECT);

  const {
    records: groupsSelect,
    isLoading: groupsLoading,
    fetchError: groupsError,
  } = useFetchList(API_GROUPS);

  return (
    <form onSubmit={handleSubmit}>
      <Paper>
        <List>
          {selectedRecord ? (
            <ListItem
              secondaryAction={
                <Tooltip title="Details" placement="bottom" arrow>
                  <IconButton
                    color="primary"
                    aria-label="details"
                    onClick={() => openInDrawer("details", selectedRecord)}
                  >
                    <ArticleIcon />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <EditIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Edit" secondary="Customer" />
            </ListItem>
          ) : (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add New" secondary="Customer" />
            </ListItem>
          )}
        </List>
        <Divider />
        <Box sx={{ p: 3 }}>
          {selectedRecord && (
            <CheckboxControl
              label="Block"
              name="block"
              value={values.block}
              onChange={handleInputChange}
            />
          )}
          <InputControl
            type="email"
            label="Email"
            name="email"
            value={values.email}
            onChange={handleInputChange}
            error={errors.email}
          />
          <InputControl
            label="First Name"
            name="firstName"
            value={values.firstName}
            onChange={handleInputChange}
            error={errors.firstName}
          />
          <InputControl
            label="Last Name"
            name="lastName"
            value={values.lastName}
            onChange={handleInputChange}
            error={errors.lastName}
          />
          {groupsError ||
            (groupsLoading && <CircularProgress size={20} color="inherit" />)}
          {groupsSelect && user?.roles.includes("Admin") && (
            <SelectControl
              label="Company"
              name="groupId"
              value={values.groupId}
              onChange={handleInputChange}
              options={groupsSelect}
              error={errors.groupId}
              textIndex={1}
            />
          )}
          <InputControl
            label="Phone No."
            name="phoneNumber"
            value={values.phoneNumber}
            onChange={handleInputChange}
            error={errors.phoneNumber}
          />
          <InputControl
            label="Address"
            name="address"
            value={values.address}
            onChange={handleInputChange}
            error={errors.address}
          />
          <InputControl
            label="City"
            name="city"
            value={values.city}
            onChange={handleInputChange}
            error={errors.city}
          />
          <InputControl
            label="State"
            name="state"
            value={values.state}
            onChange={handleInputChange}
            error={errors.state}
          />
          <InputControl
            label="Country"
            name="country"
            value={values.country}
            onChange={handleInputChange}
            error={errors.country}
          />
          <InputControl
            label="Zip Code"
            name="zipCode"
            value={values.zipCode}
            onChange={handleInputChange}
            error={errors.zipCode}
          />
          <InputControl
            label="Tax No."
            name="taxNumber"
            value={values.taxNumber}
            onChange={handleInputChange}
            error={errors.taxNumber}
          />
          <SelectControl
            label="Where did you find us"
            name="whereFindUs"
            value={values.whereFindUs}
            onChange={handleInputChange}
            options={[
              { value: "Social Media", label: "Social Media" },
              { value: "TV", label: "TV" },
              { value: "Friend", label: "Friend" },
              { value: "Google", label: "Google" },
              { value: "Other", label: "Other" },
            ]}
          />
          <InputControl
            label="Notes (About)"
            name="notes"
            value={values.notes}
            onChange={handleInputChange}
            error={errors.notes}
            multiline
            rows={3}
          />
          <FormControl margin="normal" fullWidth>
            <InputLabel>Upload Document(s)</InputLabel>
            <FilesUploader
              inputName="customerFiles"
              accept="image/*,application/pdf,application/postscript"
              values={values}
              setValues={setValues}
            />
          </FormControl>
          {userError ||
            (userIsLoading && <CircularProgress size={20} color="inherit" />)}
          {usersSelect && user?.roles.includes("Admin") && (
            <SelectControl
              label="User"
              name="userId"
              value={values.userId}
              onChange={handleInputChange}
              options={usersSelect}
              error={errors.userId}
              textIndex={1}
            />
          )}
          {shopsError ||
            (shopsLoading && <CircularProgress size={20} color="inherit" />)}
          {shopsSelect && user?.roles.includes("Admin") && (
            <SelectControl
              label="Shop"
              name="shopId"
              value={values.shopId}
              onChange={handleInputChange}
              options={shopsSelect}
              error={errors.shopId}
              textIndex={1}
            />
          )}
          {usersSelect && shopsSelect && groupsSelect && (
            <ButtonControl type="submit" text="Save" isSaving={isSaving} />
          )}
        </Box>
      </Paper>
    </form>
  );
}

export default memo(CustomerForm);
