import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
} from "@mui/material";
import React, { memo, useState } from "react";
import ApiFetch from "../../components/ApiFetch";
import InputControl from "../../components/controls/InputControl";
import SelectControl from "../../components/controls/SelectControl";
import { API_CUSTOMERS } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useComponentContext from "../../hooks/useComponentContext";
import useFetchList from "../../hooks/useFetchList";

function FormCustomerInfo({
  values,
  setValues,
  handleInputChange,
  validate,
  errors,
}) {
  const { user } = useAppContext();
  const { setNotify } = useComponentContext();
  const [loading, setLoading] = useState(false);

  const {
    records: customerOptions,
    isLoading: customerIsLoading,
    fetchError: customerFetchError,
  } = useFetchList(`${API_CUSTOMERS}/Select`);

  const handleFindCustomer = async (email) => {
    if (/^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i.test(email)) {
      setLoading(true);

      // GET Request using fetch
      const requestOptions = {
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        },
      };

      const results = await ApiFetch(
        `${API_CUSTOMERS}/${email}`,
        requestOptions,
        setNotify
      );
      if (results) {
        values.email = results.email;
        values.firstName = results.firstName;
        values.lastName = results.lastName;
        values.phoneNumber = results.phoneNumber;
        values.address = results.address;
        values.city = results.city;
        values.state = results.state;
        values.country = results.country;
        values.zipCode = results.zipCode;
        values.company = results.company;
        values.taxNumber = results.taxNumber;
        values.notes = results.notes;
        values.whereFindUs = results.whereFindUs
          ? results.whereFindUs
          : "Social Media";
      } else {
        values.firstName = "";
        values.lastName = "";
        values.phoneNumber = "";
        values.address = "";
        values.city = "";
        values.state = "";
        values.country = "";
        values.zipCode = "";
        values.company = "";
        values.taxNumber = "";
        values.whereFindUs = "Social Media";
        values.notes = "";
      }
      setValues({ ...values });
      setLoading(false);
    }
    validate();
  };

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        avatar={<PermIdentityOutlinedIcon fontSize="large" />}
        title="Customer Info"
      />
      <Divider />
      <CardContent>
        {customerFetchError ||
          (customerIsLoading && <CircularProgress size={20} color="inherit" />)}
        {customerOptions && (
          <Autocomplete
            id="combo-box"
            freeSolo
            fullWidth
            groupBy={(customerOptions) =>
              customerOptions.shopTitle &&
              "--- " + customerOptions.shopTitle.toUpperCase()
            }
            getOptionLabel={(customerOptions) => {
              return customerOptions.email;
            }}
            options={customerOptions}
            onInputChange={(e, val) => handleFindCustomer(val)}
            renderOption={(props, customerOptions) => (
              <Box component="li" {...props}>
                {customerOptions.email}
              </Box>
            )}
            renderInput={(params) => (
              <InputControl
                {...params}
                label="Email"
                name="email"
                value={values.email}
                onChange={handleInputChange}
                error={errors.email}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
        <InputControl
          label="First Name"
          name="firstName"
          value={values.firstName}
          onChange={handleInputChange}
          error={errors.firstName}
        />
        <InputControl
          label="Last Name"
          name="lastName"
          value={values.lastName}
          onChange={handleInputChange}
          error={errors.lastName}
        />
        <InputControl
          label="Address"
          name="address"
          value={values.address}
          onChange={handleInputChange}
          error={errors.address}
        />
        <InputControl
          label="City"
          name="city"
          value={values.city}
          onChange={handleInputChange}
          error={errors.city}
        />
        <InputControl
          label="State"
          name="state"
          value={values.state}
          onChange={handleInputChange}
          error={errors.state}
        />
        <InputControl
          label="Country"
          name="country"
          value={values.country}
          onChange={handleInputChange}
          error={errors.country}
        />
        <InputControl
          label="Zip Code"
          name="zipCode"
          value={values.zipCode}
          onChange={handleInputChange}
          error={errors.zipCode}
        />
        <InputControl
          type="tel"
          label="Phone Number"
          name="phoneNumber"
          value={values.phoneNumber}
          onChange={handleInputChange}
          error={errors.phoneNumber}
        />
        <InputControl
          label="Company"
          name="company"
          value={values.company}
          onChange={handleInputChange}
          error={errors.company}
        />
        <InputControl
          label="Tax No."
          name="taxNumber"
          value={values.taxNumber}
          onChange={handleInputChange}
          error={errors.taxNumber}
        />
        <SelectControl
          label="Where did you find us"
          name="whereFindUs"
          value={values.whereFindUs}
          onChange={handleInputChange}
          options={[
            { value: "Social Media", label: "Social Media" },
            { value: "TV", label: "TV" },
            { value: "Friend", label: "Friend" },
            { value: "Google", label: "Google" },
            { value: "Other", label: "Other" },
          ]}
        />
        <InputControl
          label="Notes (About)"
          name="notes"
          value={values.notes}
          onChange={handleInputChange}
          error={errors.notes}
          multiline
          rows={3}
        />
      </CardContent>
    </Card>
  );
}

export default memo(FormCustomerInfo);
