import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import ButtonControl from "../../components/controls/ButtonControl";
import InputControl from "../../components/controls/InputControl";
import SelectControl from "../../components/controls/SelectControl";
import useForm from "../../hooks/useForm";

const objRecord = {
  name: "",
  msgFor: 1,
  template: "",
  shopId: 0,
};

function SmsTemplateForm({
  addOrEdit,
  selectedRecord,
  openInDrawer,
  isSaving,
  shopId,
}) {
  const validate = (input = values) => {
    let temp = { ...errors };

    if ("name" in input)
      temp.name = input.name ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, handleInputChange, errors, setErrors, resetForm } = useForm(
    selectedRecord ? selectedRecord : objRecord,
    true,
    validate
  );

  const templateVars = {
    email: ["{{CUSTOMER-EMAIL}}", "{{CUSTOMER-NAME}}", "{{PIN}}"],
  };
  const vars = (id) => {
    switch (id) {
      case 1:
        templateVars.email = [
          "{{CUSTOMER-EMAIL}}",
          "{{CUSTOMER-NAME}}",
          "{{PIN}}",
        ];
        return templateVars;

      case 2:
        templateVars.email = [
          "{{QUOTEID}}",
          "{{QUOTE-TITLE}}",
          "{{CUSTOMER-NAME}}",
          "{{CUSTOMER-EMAIL}}",
          "{{SHOP-TITLE}}",
          "{{USER-EMAIL}}",
        ];
        return templateVars;

      case 3:
        templateVars.email = [
          "{{QUOTEID}}",
          "{{QUOTE-TITLE}}",
          "{{START-TIME}}",
          "{{END-TIME}}",
          "{{CUSTOMER-NAME}}",
        ];
        return templateVars;

      default:
        return templateVars;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    values.shopId = values.shopId ? values.shopId : shopId;
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Paper>
        <List>
          <ListItem
            secondaryAction={
              <Tooltip title="Close" placement="bottom" arrow>
                <Button
                  variant="text"
                  endIcon={<CloseIcon />}
                  onClick={() => openInDrawer()}
                >
                  Close
                </Button>
              </Tooltip>
            }
          >
            {selectedRecord ? (
              <>
                <ListItemAvatar>
                  <Avatar>
                    <EditIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Edit" secondary="SMS Template" />
              </>
            ) : (
              <>
                <ListItemAvatar>
                  <Avatar>
                    <AddIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Add New" secondary="SMS Template" />
              </>
            )}
          </ListItem>
        </List>
        <Divider />
        <Paper elevation={0} sx={{ p: 3 }}>
          <SelectControl
            label="Template For"
            name="msgFor"
            value={values.msgFor}
            onChange={handleInputChange}
            options={[
              { value: 1, label: "User PIN" },
              { value: 2, label: "Quote Sent" },
              { value: 3, label: "Install Booking" },
            ]}
          />
          <InputControl
            label="Name"
            name="name"
            value={values.name}
            onChange={handleInputChange}
            error={errors.name}
          />
          <FormControl fullWidth margin="normal">
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography variant="subtitle2">{`Message {vars}:`}</Typography>
              </Grid>
              {vars(values.msgFor).email.map((el, i) => (
                <Grid item key={i}>
                  <Chip label={el} />
                </Grid>
              ))}
            </Grid>
            <InputControl
              label="SMS Editor"
              name="template"
              value={values.template}
              onChange={handleInputChange}
              multiline
              rows={5}
              helperText={
                <Typography>CHARACTERS: {values.template.length}</Typography>
              }
            />
          </FormControl>
          <ButtonControl type="submit" text="Save" isSaving={isSaving} />
        </Paper>
      </Paper>
    </form>
  );
}

export default memo(SmsTemplateForm);
